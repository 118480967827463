body {
	margin: 0;
	font-family: -apple-system, "Roboto", "Oxygen", "Ubuntu", "Cantarell",
		"Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

p.MuiFormHelperText-root span {
	display: none;
}

.MuiTableCell-head {
	font-weight: bold !important;
}

@media (min-width: 600px) {
	main > div:nth-child(2) {
		padding: 24px;
	}
}

main > div.MuiDrawer-root > div.MuiPaper-root {
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.MuiTable-root td,
.MuiTable-root th {
	white-space: nowrap;
}

.ra-rich-text-input .ql-editor p:not(:last-child) {
	margin-bottom: 0 !important;
}
